import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: grid;
  place-items: center;

  div {
    height: 20vh;
    display: grid;
    place-items: center;
  }
`
